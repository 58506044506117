import React from "react";

import DocumentRowEdit from "../../../../../../components/ui/Document/DocumentRowEdit";
import DocumentRowShow from "../../../../../../components/ui/Document/DocumentRowShow";
import { DOCUMENT_STATE_ENUM } from "../../../../../../constants/enums/documentState.enum";

const UploadedDocument = ({ document, documentTypes, onDocumentUpdated, onDocumentDeleted, upload }) => {
    return document.status.value !== DOCUMENT_STATE_ENUM.DRAFT ? (
        <DocumentRowShow document={document} />
    ) : (
        <DocumentRowEdit
            upload={upload}
            document={document}
            documentTypes={documentTypes}
            onDocumentDeleted={onDocumentDeleted}
            onDocumentUpdated={onDocumentUpdated}
        />
    );
};

export default UploadedDocument;
