import { HiUpload } from "@react-icons/all-files/hi/HiUpload";
import { useRequest } from "ahooks";
import React, { useContext, useState } from "react";
import { HiEnvelope } from "react-icons/hi2";
import { useNavigate, useParams } from "react-router-dom";

import PageContainer from "../../../../../components/layout/ProtectedLayout/_components/PageContainer";
import AdaptableCard from "../../../../../components/shared/AdaptableCard";
import Tabs from "../../../../../components/ui/Tabs";
import TabContent from "../../../../../components/ui/Tabs/TabContent";
import TabList from "../../../../../components/ui/Tabs/TabList";
import TabNav from "../../../../../components/ui/Tabs/TabNav";
import { UPLOAD_STATE_ENUM } from "../../../../../constants/enums/uploadState.enum";
import documentService from "../../../../../services/documentService";
import uploadService from "../../../../../services/uploadService";
import { UploadsContext } from "../Uploads";
import UploadDocumentDropzone from "./_components/UploadDocumentDropzone";
import UploadHeader from "./_components/UploadHeader";
import UploadNotificationForm from "./_components/UploadNotificationForm";
import UploadNotificationShow from "./_components/UploadNotificationShow";
import UploadedDocument from "./_components/UploadedDocument";

const Upload = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const { data: upload, mutate, loading } = useRequest(uploadService.fetch, { defaultParams: [id] });
    const { documentTypes } = useContext(UploadsContext);
    const filteredDocumentTypes = documentTypes.filter(d => d.document_category?.id === upload?.document_category?.id);

    const handleUploadUpdated = res => mutate(res);

    const handleUploadDestroyed = () => navigate("../");

    const handleDocumentUpload = res =>
        mutate(old => ({
            ...old,
            documents: [...old.documents, res],
        }));

    const handleDocumentUpdated = document =>
        mutate({
            ...upload,
            documents: upload.documents.map(d => (d.uuid !== document.uuid ? d : document)),
        });
    const handleDocumentDeleted = uuid =>
        mutate({
            ...upload,
            documents: upload.documents.filter(d => d.uuid !== uuid),
        });

    const [currentTab, setCurrentTab] = useState("file-upload");

    return (
        <PageContainer>
            <UploadHeader upload={upload} onDestroyed={handleUploadDestroyed} onUpdated={handleUploadUpdated} />

            <AdaptableCard>
                <Tabs defaultValue='file-upload' value={currentTab} onChange={value => setCurrentTab(value)}>
                    <TabList>
                        <TabNav key='file-upload' value='file-upload' icon={<HiUpload />}>
                            {" "}
                            Upload{" "}
                        </TabNav>
                        <TabNav key='notification-settings' value='notification-settings' icon={<HiEnvelope />}>
                            {" "}
                            Notifiche{" "}
                        </TabNav>
                    </TabList>

                    <TabContent value='file-upload'>
                        <div className='px-4 py-6'>
                            {upload && (
                                <>
                                    <UploadDocumentDropzone upload={upload} onDocumentUploaded={handleDocumentUpload} />

                                    <div className={upload?.status?.value === UPLOAD_STATE_ENUM.DRAFT ? "" : "grid xl:grid-cols-2"}>
                                        {documentService.reorderDocumentsByMediaFileName(upload?.documents || []).map((document, index) => (
                                            <UploadedDocument
                                                upload={upload}
                                                key={index}
                                                document={document}
                                                documentTypes={filteredDocumentTypes}
                                                onDocumentUpdated={handleDocumentUpdated}
                                                onDocumentDeleted={handleDocumentDeleted}
                                            />
                                        ))}
                                    </div>
                                </>
                            )}
                        </div>
                    </TabContent>
                    <TabContent value='notification-settings'>
                        <div className='px-4 py-6'>
                            {upload?.status?.value === UPLOAD_STATE_ENUM.DRAFT ? (
                                <UploadNotificationForm upload={upload} mutate={mutate} loading={loading} />
                            ) : (
                                <UploadNotificationShow upload={upload} />
                            )}
                        </div>
                    </TabContent>
                </Tabs>
            </AdaptableCard>
        </PageContainer>
    );
};

export default Upload;
