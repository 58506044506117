import { useRequest } from "ahooks";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { HiDocumentText } from "react-icons/hi";

import documentService from "../../../services/documentService";
import uploadService from "../../../services/uploadService";
import { DownloadOrPreview } from "../../../utils/files";
import uploadDocumentFormConfig from "../../../views/protected/admin/uploads/upload/_components/uploadDocumentFormConfig";
import FormGroup from "../../RefactoredForms/FormGroup";
import FormSelect from "../../RefactoredForms/FormSelect";
import DestroyButton from "../Buttons/_components/DestroyButton";
import DownloadButton from "../Buttons/_components/DownloadButton";
import PreviewButton from "../Buttons/_components/PreviewButton";
import SaveButton from "../Buttons/_components/SaveButton";
import PageHeader from "../Page/PageHeader";
import DocumentStateDisplay from "../State/DocumentStateDisplay";
import { DatePicker, Notification, toast } from "../index";

const DocumentRowEdit = ({ document, documentTypes, onDocumentUpdated, onDocumentDeleted, upload }) => {
    const { loading, runAsync } = useRequest(uploadService.destroyDocument, { manual: true });
    const [initialValues, setInitialValues] = useState(uploadDocumentFormConfig.initialValues);

    useEffect(() => setInitialValues(uploadDocumentFormConfig.setInitialValues(document)), [document]);
    const handleDownload = () => {
        documentService
            .download(document?.media?.url)
            .then(DownloadOrPreview)
            .catch(err => toast.push(<Notification title={"Impossibile avviare il download"} type='danger' />));
    };

    const handleDocumentDelete = () =>
        runAsync(upload.uuid, document.uuid)
            .then(res => {
                toast.push(<Notification title='Document eliminato con successo' type='success' />);
                onDocumentDeleted(document.uuid);
            })
            .catch(err => toast.push(<Notification title={err.response.data.message} type='danger' />));

    return (
        <div className='flex justify-start items-center p-4 gap-2 hover:bg-neutral-100 '>
            <div>
                <HiDocumentText color='#d4d4d4' size={100} />
                <div className='text-center'>
                    <DocumentStateDisplay state={document.status} auto_publish_at={document.auto_publish_at} />
                </div>
            </div>

            {/* START FORM */}
            <Formik
                initialValues={initialValues}
                validateOnMount={true}
                validationSchema={uploadDocumentFormConfig.formValidation}
                validateOnBlur={true}
                initialTouched={{
                    document_type: true,
                }}
                enableReinitialize={true}
                onSubmit={(values, { setSubmitting }) => {
                    uploadService
                        .updateDocument(upload.uuid, document.uuid, uploadService.mapDocumentValuesToApi(values))
                        .then(res => {
                            toast.push(<Notification title={`${document?.media.name} aggiornato con successo`} type='success' />);
                            onDocumentUpdated(res);
                        })
                        .catch(error => toast.push(<Notification title={error.response.data.message} type='success' />))
                        .finally(() => setSubmitting(false));
                }}
            >
                {({ setValues, dirty, setFieldValue, setFieldTouched, isSubmitting, values }) => (
                    <Form className='flex-grow'>
                        <div className='flex flex-col gap-1'>
                            <div className='flex justify-start gap-1 align-middle'>
                                <div className='font-semibold text-lg text-sky-800'>{document.media?.name}</div>
                                <DownloadButton
                                    size='xs'
                                    url={document?.media?.url}
                                    label={document.media?.file_name}
                                    filename={document?.media?.file_name}
                                />
                                <PreviewButton
                                    size='xs'
                                    url={document?.media?.url}
                                    label={document.media?.file_name}
                                    filename={document?.media?.file_name}
                                />
                            </div>

                            <div className='grid xl:grid-cols-3 gap-4'>
                                <FormGroup required label='Seleziona una tipologia' name='document_type'>
                                    <FormSelect
                                        isClearable={true}
                                        name='document_type'
                                        placeholder='Seleziona una tipologia'
                                        onBlur={() => setFieldTouched("document_type")}
                                        onChange={value =>
                                            setValues({
                                                ...values,
                                                document_type: value,
                                                expiration: null,
                                                month: null,
                                                year: null,
                                            })
                                        }
                                        getOptionValue={opt => opt.id}
                                        getOptionLabel={opt => opt.name}
                                        options={documentTypes}
                                    />
                                </FormGroup>

                                <FormGroup required={values.document_type?.is_expiration_required} label='Data di scadenza' name='expiration'>
                                    <DatePicker
                                        placeholder='Data di scadenza'
                                        disabled={!values.document_type?.is_expiration_required}
                                        amPm={false}
                                        clearable={true}
                                        labelFormat={{ month: "MM", year: "YYYY" }}
                                        inputFormat='DD/MM/YYYY'
                                        value={values?.expiration || null}
                                        onBlur={() => setFieldTouched("expiration")}
                                        onChange={value => setFieldValue("expiration", value)}
                                        name='expiration'
                                    />
                                </FormGroup>

                                <FormGroup label='Data di pubblicazione' name='auto_publish_at'>
                                    <DatePicker
                                        placeholder='Data di pubblicazione'
                                        disabled={!values.document_type?.is_expiration_required}
                                        amPm={false}
                                        clearable={true}
                                        labelFormat={{ month: "MM", year: "YYYY" }}
                                        inputFormat='DD/MM/YYYY'
                                        value={values?.auto_publish_at || null}
                                        onBlur={() => setFieldTouched("auto_publish_at")}
                                        onChange={value => setFieldValue("auto_publish_at", value)}
                                        name='auto_publish_at'
                                    />
                                </FormGroup>
                            </div>
                            <div className='flex justify-end gap-4'>
                                <SaveButton loading={isSubmitting} disabled={!dirty} />
                                <DestroyButton
                                    handleClick={() => handleDocumentDelete()}
                                    message={`Sei sicuro di voler eliminare il documento ${document?.media?.name}?`}
                                    loading={loading}
                                />
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            {/* END FORM */}
        </div>
    );
};

export default DocumentRowEdit;
